import Head from "next/head";

const SEOHead = ({
  title,
  description,
  type,
  url,
  imageUrl,
  author,
  twitterHandler,
  readTime,
}) => {
  return (
    <Head>
      <title>{title || "Sparkle Infotech"}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta data-rh="true" charSet="utf-8" />
      <meta
        data-rh="true"
        name="viewport"
        content="width=device-width,minimum-scale=1,initial-scale=1,maximum-scale=1"
      />
      <meta
        data-rh="true"
        property="fb:app_id"
        content={process.env.NEXT_PUBLIC_FB_APP_ID}
      />
      <meta data-rh="true" property="og:site_name" content="Sparkle Infotech" />
      <meta data-rh="true" property="og:type" content={type} />
      <meta data-rh="true" name="title" content={title} />
      <meta data-rh="true" property="og:title" content={title} />
      <meta data-rh="true" name="description" content={description} />
      <meta data-rh="true" property="og:description" content={description} />
      <meta data-rh="true" property="og:url" content={url} />
      <meta data-rh="true" property="al:web:url" content={url} />
      <meta data-rh="true" property="og:image" content={imageUrl} />
      <meta data-rh="true" property="og:image:secure_url" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta data-rh="true" property="article:author" content={author} />
      <meta data-rh="true" name="author" content={author} />
      <meta
        data-rh="true"
        name="robots"
        content="index,follow,max-image-preview:large"
      />
      <meta data-rh="true" name="referrer" content="unsafe-url" />
      <meta data-rh="true" property="twitter:title" content={title} />
      <meta data-rh="true" name="twitter:site" content={twitterHandler} />
      <meta data-rh="true" name="twitter:app:url:iphone" content={url} />
      <meta
        data-rh="true"
        property="twitter:description"
        content={description}
      />
      <meta data-rh="true" name="twitter:image:src" content={imageUrl} />
      <meta data-rh="true" name="twitter:card" content="summary_large_image" />
      <meta data-rh="true" name="twitter:creator" content={twitterHandler} />
      <meta data-rh="true" name="twitter:label1" content="Reading time" />
      <meta data-rh="true" name="twitter:data1" content={readTime} />
      <meta data-rh="true" name="twitter:tile:template:testing" content="2" />
      <meta data-rh="true" name="twitter:tile:image" content={imageUrl} />
      <link rel="canonical" href={process.env.NEXT_PUBLIC_SITE_URL} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Company",
            "name": "Sparkle Infotech",
            "description": "We are an award-winning Indian software engineering company focused on providing outsourced software development and software engineering teams to our clients globally.",
            "url": "${url}",
            "sameAs": ["https://twitter.com/${twitterHandler}"],
            "image": "${imageUrl}"
          }
        `}
      </script>
    </Head>
  );
};

export default SEOHead;
